console.log('app.js');
// require jQuery normally
const $ = require('jquery');
global.$ = global.jQuery = $;

/* create global $ and jQuery variables */
global.$ = global.jQuery = $;

require('bootstrap');

var Cookies = require('./cookies.js');

require('../scss/main.scss');

function isEmpty(obj) { if (typeof (obj) !== "undefined" && obj !== null && (obj.length > 0 || typeof (obj) == 'number' || typeof (obj.length) == "undefined") && obj !== "undefined") return false; else return true; }

/* CTA en Posts */
$(document).ready(function() {
  $.fn.scrollTo = function () {
      if (!isEmpty(this)) {
          $('html, body').animate({
              scrollTop: $(this).offset().top - 40
          }, 1000);
      }
  }

  /*$("html").bind("mouseleave", function () {
    $("#cta-modal-leave").modal('show');
    $("html").unbind("mouseleave");
  });*/

  $.fn.isInViewport = function () {
    let elementTop = $(this).offset().top;
    let elementBottom = elementTop + $(this).outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};


  var isMobile = false; //initiate as false
  // device detection
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    isMobile = true;
  }

  if(isMobile) {
    if( $('#cta-modal-leave').length ) {
      setTimeout(function() {
        $(window).scroll(function () {
          if ($('footer').isInViewport()) {
            $("#cta-modal-leave").modal('show');
          }
        });
      }, 3000);
    }
  }

  $("[data-toggle=popover]").popover({html:true})

  /* Call to Call */
  $("#sendCTC").on("click", function(event) {
    event.preventDefault();
    $(this).tooltip('show');
    if($("#num_telefono").val() != "" && $("#politica_privacidad").is(":checked")) {

      $(this).tooltip('hide');
      $("[data-status='not-sent']").fadeToggle(function() {
        $("[data-status='sent']").fadeToggle();
        // TODO: SEND FORM
      });
    }
  });
  /* ./ Call to Call */

  /* MEGA MENU */
  $(".dropdown-menu").on("click", function(event) {
    event.stopPropagation();
  });

  function resetClassesHover(div) {
    $(div).removeClass("single_btn");
  }

  $(".image-hover li").on("mouseover", function() {
    $(this).closest("div").next().find(".transform-image-hover")
      .attr("src", $(this).find("a").data("src"));

    let attrs = $(this).find("a");

    if(attrs.data("type") == "single_btn") {
      resetClassesHover($(this).closest("div").next());
      $(this).closest("div").next().addClass("single_btn");
    }
  });
  /* ./ MEGA MENU */

  $('img.svg').each(function(){
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass+' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');
  });



  $("body").on("click", ".scroll-to", function (e) {
    var t = $(this).attr("data-scroll-to");
    if (isEmpty(t)) t = $(this).attr("href");
    try {
        $(t).scrollTo();
    } catch (e) { }
    if (t.indexOf("#") == 0 && $(this).hasClass("btn")) e.preventDefault();
  });


  if (location.href.indexOf("#") != -1) {
    var t = location.href.substr(location.href.indexOf("#"));
    try {
      console.log(t);
        $(t).scrollTo();
    } catch (e) { 
      console.log(e);
    }
  }

  document.addEventListener('DOMContentLoaded', function () {
    $(".faq-container h4, .advantages-accordion h3").on('click', function () {
      let icon = $(this).find("svg").attr('data-icon');
      if(icon == "minus") $(this).find("svg").attr('data-icon', 'plus');
      else $(this).find("svg").attr('data-icon', 'minus');
    });
  });
});
/* ./ CTA en Posts */

document.addEventListener("DOMContentLoaded", function () {
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

  if ("IntersectionObserver" in window) {
    var lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });
    lazyBackgrounds.forEach(function (lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
}); // Lazy load for images

document.addEventListener("DOMContentLoaded", function () {
  var lazyImages = [].slice.call(document.querySelectorAll("img.lazy-image"));
  var active = false;

  var lazyLoad = function lazyLoad() {
    if (active === false) {
      active = true;
      setTimeout(function () {
        lazyImages.forEach(function (lazyImage) {
          if (lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0 && getComputedStyle(lazyImage).display !== "none" && !!( lazyImage.offsetWidth || lazyImage.offsetHeight || lazyImage.getClientRects().length )) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy-image");
            lazyImages = lazyImages.filter(function (image) {
              return image !== lazyImage;
            });

            if (lazyImages.length === 0) {
              document.removeEventListener("scroll", lazyLoad);
              window.removeEventListener("resize", lazyLoad);
              window.removeEventListener("orientationchange", lazyLoad);
            }
          }
        });
        active = false;
      }, 200);
    }
  };

  document.addEventListener("scroll", lazyLoad);
  window.addEventListener("resize", lazyLoad);
  window.addEventListener("orientationchange", lazyLoad);
  window.addEventListener("load", lazyLoad);
});

Cookies.init();

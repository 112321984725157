"use strict";

var cookieDisclaimer = '_up_cookies_disclaimer';
var cookieDuration = 365;

console.log('cookies');

function init() {
    // Comprobar si existe la cookie de aceptación o negación
    let cookieExists = getCookie(cookieDisclaimer);

    if(cookieExists == null) {
        // Si no existe mostrar banner
        showDisclaimer();
    }

}

function showDisclaimer() {
    let codeDisclaimer = `<div class="us-cookies-disclaimer row align-items-center">
        <div class="us-cookies-content col-12 col-lg-7 text-center text-lg-left mb-2 mb-lg-0">
            Chèque Déjeuner España S.A.U. utiliza cookies propias y de terceros con finalidad analítica y/o publicitaria. <a href="/politica-cookies/" class="text-white">Consulta la política de cookies</a>
        </div>
        <div class="us-cookies-action-btn col-12 col-lg text-center text-lg-right">
            <a href="#" class="btn-accept-cookies">Aceptar todas</a>
            <a href="#" class="btn-deny-cookies">Rechazar</a>
            <a href="#" class="btn-more-info">Configurar</a>
        </div>
    </div>`;

    $('body').append(codeDisclaimer);
}

function removeDisclaimer() {
    if($('.us-cookies-disclaimer').length) $('.us-cookies-disclaimer').remove();
}

function acceptCookies() {
    setCookie(cookieDisclaimer, 1, cookieDuration);
    tarteaucitron.userInterface.respondAll(true);
}

function denyCookies() {
    setCookie(cookieDisclaimer, 0, cookieDuration);
    tarteaucitron.userInterface.respondAll(false);
}

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
}

function setCookie(name, value, days) {
    var d = new Date;
    d.setTime(d.getTime() + 24*60*60*1000*days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}


module.exports = {init};


$(function() {
    $(document).on('click', '.us-cookies-disclaimer .btn-accept-cookies', function(e) {
        e.preventDefault();
        acceptCookies();
        removeDisclaimer();
    });

    $(document).on('click', '.us-cookies-disclaimer .btn-deny-cookies', function(e) {
        e.preventDefault();
        denyCookies();
        removeDisclaimer();
    });

    $(document).on('click', '.us-cookies-disclaimer .btn-more-info', function(e) {
        e.preventDefault();
        tarteaucitron.userInterface.openPanel();
    });

    console.log('trigger');

    $(window).on("cierra_cookies", function(e) {
        e.preventDefault();
        acceptCookies();
        removeDisclaimer();
    })
})
